import React from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();


    return (

       <>
           <div className="mt-5 d-flex justify-content-end">
               <Link to={"/register"}>Register</Link>
           </div>
           <div className="mt-5">
               <h2>Login</h2>
               <Formik
                   initialValues={{email: '', password: ''}}
                   validationSchema={Yup.object({
                       email: Yup.string().email('Invalid email address').required('The email field is required.'),
                       password: Yup.string().required('The password field is required')
                   })}
                   onSubmit={(values, {setSubmitting}) => {
                       axios.post('https://auth.smehr.pegotec.dev/api/auth/login', values)
                           .then(response => {
                               localStorage.setItem('token', response.data.access_token); // save token to localStorage
                               localStorage.setItem('user',{email:values.email}); // save token to localStorage
                               // handle successful login, e.g., save token
                               navigate('/time-off');
                           })
                           .catch(error => {

                               alert(error?.response?.data?.message)
                               // handle error
                               console.error('There was an error logging in!', error);
                           })
                           .finally(() => setSubmitting(false));
                   }}
               >
                   {({isSubmitting}) => (
                       <Form>
                           <div className="mb-3">
                               <label htmlFor="email">Email Address</label>
                               <Field name="email" type="email" className="form-control"/>
                               <ErrorMessage name="email" component="div" className="text-danger"/>
                           </div>
                           <div className="mb-3">
                               <label htmlFor="password">Password</label>
                               <Field name="password" type="password" className="form-control"/>
                               <ErrorMessage name="password" component="div" className="text-danger"/>
                           </div>
                           <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                               Login
                           </button>
                       </Form>
                   )}
               </Formik>

           </div>
       </>
    );
};

export default Login;
