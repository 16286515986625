import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

const TimeOff = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const [holidays, setHolidays] = useState([]);
    useEffect(() => {

        if (!token) {
            navigate('/login');
        }
        getHolidays()
    }, [navigate]);

    const handleLogout = () => {


        axios.post('https://auth.smehr.pegotec.dev/api/auth/logout', user, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                localStorage.removeItem('token'); // remove token from localStorage
                localStorage.removeItem('user'); // remove token from localStorage
                // handle successful logout
                navigate('/login');
            })
            .catch(error => {
                // handle error
                console.error('There was an error logging out!', error);
            });
    };

    const getHolidays = () => {
        axios.get('https://timeoff.smehr.pegotec.dev/api/time-off/holidays', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                console.log(response.data?.data)
                setHolidays(response.data?.data)
            })
            .catch(error => {
                // handle error
                console.error('There was an error logging out!', error);
            });
    }

    return (

        <>
            <div className="mt-5 d-flex justify-content-end">
                <a href="#" onClick={handleLogout}>Logout</a>
            </div>
            <div className="mt-5">


                <h4>List of Holidays</h4>
                <table className="table table-responsive table-bordered">
                    <thead>
                    <tr>
                        <th>SN</th>
                        <th>Holiday</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        holidays?.map((data, i) => (
                            <tr key={`holiday-${i}`}>
                                <td>{i + 1}</td>
                                <td>{data}</td>
                            </tr>
                        ))
                    }

                    </tbody>
                </table>
            </div>
        </>
    );
};

export default TimeOff;
